import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <blockquote>
      <p parentName="blockquote">{`If you have trouble with one of the steps below, make sure to checkout the
Troubleshooting section.`}</p>
    </blockquote>
    <h2 {...{
      "id": "project-setup"
    }}>{`Project Setup`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make sure Docker, Node (>= version 18), and Yarn are installed.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Docker`}</li>
          <li parentName="ul">{`Node (>= version 18)`}</li>
          <li parentName="ul">{`Yarn`}</li>
          <li parentName="ul">{`Erlang (OTP 25)`}</li>
          <li parentName="ul">{`Elixir (1.15)`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure the npm registry`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Create a `}<a parentName="li" {...{
              "href": "https://github.com/settings/tokens/new"
            }}>{`Personal Access Token`}</a>{`
and give it the "repo" and "read:packages" scopes (No expiration).`}</li>
          <li parentName="ul">{`Copy this into `}<inlineCode parentName="li">{`~/.npmrc`}</inlineCode>
            <pre parentName="li"><code parentName="pre" {...{}}>{`//npm.pkg.github.com/:_authToken="<<token goes here>>"
@kualibuild:registry=https://npm.pkg.github.com/
`}</code></pre>
          </li>
          <li parentName="ul">{`Copy this into `}<inlineCode parentName="li">{`~/.zshrc`}</inlineCode>{` or `}<inlineCode parentName="li">{`~/.bashrc`}</inlineCode>{` or `}<inlineCode parentName="li">{`~/.bash_profile`}</inlineCode>{`. Make sure it
comes `}<strong parentName="li">{`BEFORE`}</strong>{` any nvm references (`}<a parentName="li" {...{
              "href": "https://docs.npmjs.com/using-private-packages-in-a-ci-cd-workflow"
            }}>{`why?`}</a>{`):`}
            <pre parentName="li"><code parentName="pre" {...{}}>{`export PATH="$PATH:$(yarn global bin)"
export KUALIBUILD_NPM_TOKEN="<<token goes here>>"
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install the build cli: `}<inlineCode parentName="p">{`yarn global add @kualibuild/build-cli`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a folder for your kuali code: `}<inlineCode parentName="p">{`mkdir -p ~/code/kuali`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setup the builder-ui project:
`}<inlineCode parentName="p">{`cd ~/code/kuali && git clone git@github.com:kualibuild/builder-ui.git && cd builder-ui && yarn`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setup the platform-api project:
`}<inlineCode parentName="p">{`cd ~/code/kuali && git clone git@github.com:kualibuild/platform-api.git && cd platform-api && yarn`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setup the platform project:
`}<inlineCode parentName="p">{`cd ~/code/kuali && git clone git@github.com:kualibuild/platform.git && cd platform && mix do deps.get, deps.compile`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run: `}<inlineCode parentName="p">{`mkdir ~/code/kuali/__ && touch ~/code/kuali/__/build.services.json5`}</inlineCode>{`
and then copy this into that new file:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-json5"
      }}>{`{
  undocked: ['../builder-ui', '../platform'],
  run: [
    '../platform-api/packages/forms-api',
    '../platform-api/packages/workflows-api'
  ]
}
`}</code></pre>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">
        <p parentName="li">{`Start the platform services: `}<inlineCode parentName="p">{`cd ~/code/kuali/__ && build up`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run migrations for the platform project:
`}<inlineCode parentName="p">{`cd ~/code/kuali/platform && mix do ecto.create, ecto.migrate`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once that's finished, start the ui project:
`}<inlineCode parentName="p">{`cd ~/code/kuali/builder-ui && yarn dev`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In another terminal tab/window, start the platform project:
`}<inlineCode parentName="p">{`cd ~/code/kuali/platform && mix phx.server`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`npx @kualibuild/bootstrap@latest`}</inlineCode>{`. This will create all needed
databases in core and build, grant full permissions to the `}<inlineCode parentName="p">{`kualiadmin`}</inlineCode>{`
user, and add Build to the grid menu.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Login at `}<inlineCode parentName="p">{`https://local.kualibuild.com`}</inlineCode>{` with user: kualiadmin, password:
admin`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "next-steps"
    }}>{`Next Steps`}</h2>
    <p>{`Head on over and read the `}<a parentName="p" {...{
        "href": "/faq"
      }}>{`Frequently Asked Questions`}</a>{`.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <h3 {...{
      "id": "chrome-wont-let-me-bypass-the-security-warning-locally-"
    }}>{`Chrome won't let me bypass the security warning locally :(`}</h3>
    <p>{`Click anywhere on the warning page and then type "thisisunsafe". Yes, this
seriously works.`}</p>
    <h3 {...{
      "id": "im-getting-permission-errors-when-trying-to-install-the-build-cli"
    }}>{`I'm getting permission errors when trying to install the build cli`}</h3>
    <p>{`Check your error logs and see where npm/yarn is trying to fetch the cli from. If
you see `}<inlineCode parentName="p">{`https://npm.pkg.github.com/@kualibuild%2fbuild-cli`}</inlineCode>{` then chances are
that you actually don't have permissions to get the cli. Contact someone on the
platform team and they can get you added.`}</p>
    <p>{`If npm/yarn is trying to fetch
`}<inlineCode parentName="p">{`https://registry.npmjs.org/@kualibuild%2fbuild-cli`}</inlineCode>{` then you've got a different
issue. That's the wrong registry and indeed, our cli doesn't exist there. This
happens when (for some unknown reason) yarn doesn't honor the settings in
.npmrc. The fix here is to create a file called `}<inlineCode parentName="p">{`~/.yarnrc`}</inlineCode>{` and copy the
following snippet into it:`}</p>
    <pre><code parentName="pre" {...{}}>{`registry "https://npm.pkg.github.com/"
"@kualibuild:registry" "https://npm.pkg.github.com"
`}</code></pre>
    <p>{`Then run `}<inlineCode parentName="p">{`npm login --registry=https://npm.pkg.github.com --scope=@kualibuild`}</inlineCode>{`.
Once you've done this you should be able to resume the instructions above by
installing the build cli.`}</p>
    <h3 {...{
      "id": "im-getting-a-weird-error-about-the-aws-region-not-being-valid-when-running-build-up"
    }}>{`I'm getting a weird error about the AWS REGION not being valid when running build up`}</h3>
    <p>{`Try creating another entry in `}<inlineCode parentName="p">{`~/.aws/config`}</inlineCode>{` and `}<inlineCode parentName="p">{`~/.aws/credentials`}</inlineCode>{` with all
the same data but name the profile `}<inlineCode parentName="p">{`default`}</inlineCode>{`. In particular, it is known that a
`}<inlineCode parentName="p">{`default`}</inlineCode>{` profile is required to exist in `}<inlineCode parentName="p">{`~/.aws/config`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "i-see-this-error-when-running-build-up-syntaxerror-unexpected-token-"
    }}>{`I see this error when running build up: SyntaxError: Unexpected token .`}</h3>
    <p>{`This probably means you're running an unsupported version of node. Make sure
you've installed and are using node >= 16. The build cli use the `}<inlineCode parentName="p">{`?.`}</inlineCode>{` operator
which was added in that version.`}</p>
    <h3 {...{
      "id": "build-up-shows-errors-when-pulling-some-or-all-of-the-images"
    }}>{`build up shows errors when pulling some or all of the images`}</h3>
    <p>{`First check and see which images are failing. If you're failing to pull mongo,
redis, maildev, or postgres then there's a good chance you don't have docker
installed/running properly. Those images come straight from the public docker
registry. Go troubleshoot docker on The Google until you're able to successfully
run `}<inlineCode parentName="p">{`docker -D run hello-world`}</inlineCode>{`. Then come back and try again.`}</p>
    <p>{`If you're unable to pull identity or ingress it means you're not correctly
authenticated with Github. Sync up with someone on the platform team and they
can make sure you're added to the right Github team.`}</p>
    <p>{`If you can't pull the rest (proxy, builder-api, forms-api, etc.) it probably
means your aws credentials don't have access to our ECR buckets. Shoot an email
over to `}<a parentName="p" {...{
        "href": "mailto:ops@kuali.co"
      }}>{`ops@kuali.co`}</a>{` and they'll help you get everything sorted out.`}</p>
    <h3 {...{
      "id": "im-trying-to-run-vscode-over-ssh-but-docker-login-exits-with-error-1"
    }}>{`I'm trying to run vscode over ssh but docker login exits with error 1`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/docker/cli/issues/1263"
      }}>{`https://github.com/docker/cli/issues/1263`}</a></p>
    <h3 {...{
      "id": "npx-kualibuildbootstrap-is-hanging"
    }}>{`npx @kualibuild/bootstrap is hanging`}</h3>
    <p>{`Sometimes our services start up in a bad order and crash. Look through the logs
of each service and restart the ones that failed. I recommend using `}<a parentName="p" {...{
        "href": "https://github.com/jesseduffield/lazydocker"
      }}>{`lazydocker`}</a>{`
for this.`}</p>
    <h3 {...{
      "id": "when-i-go-to-localkualibuildcom-it-loads-forever"
    }}>{`When I go to local.kualibuild.com it loads forever`}</h3>
    <p>{`Same problem as above. Sometimes our services start up in a bad order and crash.
Look through the logs of each service and restart the ones that failed. I
recommend using `}<a parentName="p" {...{
        "href": "https://github.com/jesseduffield/lazydocker"
      }}>{`lazydocker`}</a>{` for this.`}</p>
    <h3 {...{
      "id": "tests-report-error-watching-file-for-changes-emfile-on-my-mac"
    }}>{`Tests report Error watching file for changes: EMFILE on my mac`}</h3>
    <pre><code parentName="pre" {...{}}>{`brew install watchman
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      